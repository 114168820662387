(function ($) {
  "use strict";

  $("#contactForm").on("submit", function (e) {
    e.preventDefault();

    // Check required fields
    let errors = false;

    $(this)
      .find(".form-control")
      .each(function () {
        $(this).removeClass("invalid");

        if (!this.value) {
          errors = true;
          $(this).addClass("invalid");
        }
      });

    if (errors) return false;

    const data = $(this).serialize();

    // AJAX Send
    $.ajax({
      url: "/contact.php",
      type: "POST",
      data: data,
    })
      .done(function (msg) {
        $("#contactForm").trigger("reset").html(msg);
      })
      .fail(function (xhr, status, error) {
        // error handling
        console.log(xhr, status, error);
      });
  });
})(jQuery);
